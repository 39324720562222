import React, { useState, useEffect } from 'react'
import HRConnect from '../../apis/hrconnect'
import MonthlyList from './MonthlyList'

const liff = window.liff

const Monthly = () => {
  const [profile, setProfile] = useState({})
  const [userData, setUserData] = useState([])
  const [slipData, setSlipData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    if (Object.keys(profile).length === 0) {
      liff.init(
        { liffId: '1653813164-7oQeQPoO' },
        async () => {
          if (liff.isLoggedIn()) {
            setProfile(await liff.getProfile())
            console.log(profile)
          } else {
            //liff.login({ redirectUri: 'http://localhost:3000/monthly' })
            liff.login()
          }
        },
        (e) => {
          console.log(e.message)
          setError('Line Login Error')
        }
      )
    }
  })

  useEffect(() => {
    if (profile.userId !== undefined) {
      HRConnect.post('/initUsers/info', { q: profile.userId })
        .then((res) => {
          console.log(res.data)
          setUserData(res.data)
        })
        .catch((e) => {
          console.log(e.message)
          setError('Cannot Connect to HRConnect Server')
        })
    }
  }, [profile])

  useEffect(() => {
    const updateCounter = async () => {
      await HRConnect.post('/counter', {
        page: 'driver-monthly',
        line_id: profile.userId,
      })
        .then((res) => {
          setIsLoading(false)
          console.log(res.data)
        })
        .catch((e) => console.log(e.message))
    }

    const getSlip = async () => {
      await HRConnect.post('/FairSlip/slip', {
        thai_id: userData[0].thai_id,
      })
        .then((res) => {
          setIsLoading(false)
          setSlipData(res.data)
        })
        .catch((e) => console.log(e.message))
    }

    if (userData[0] !== undefined) {
      updateCounter()
      getSlip()
    }
  }, [userData])

  if (userData.length === 0) {
    return <div className="ui loading segment">Loading...</div>
  }

  return <MonthlyList userData={userData} data={slipData} profile={profile} />
}

export default Monthly
