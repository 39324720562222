import React, { useState, useEffect } from 'react'
import moment from 'moment'
import HRConnect from '../../apis/hrconnect'
import SVLDriver from '../../apis/svldriver'
import DNList from './DNList'

const liff = window.liff

const Daily = () => {
  const [profile, setProfile] = useState({})
  const [userData, setUserData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [dialyData, setDailyData] = useState(null)
  const [buttonMode, setButtonMode] = useState(0)
  const [error, setError] = useState('')

  useEffect(() => {
    if (Object.keys(profile).length === 0) {
      liff.init(
        { liffId: '1653813164-L85Y52eb' },
        async () => {
          if (liff.isLoggedIn()) {
            setProfile(await liff.getProfile())
          } else {
            //liff.login({ redirectUri: 'http://localhost:3000/daily' })
            liff.login()
          }
        },
        (e) => console.log(e.message)
      )
    }
    console.log(profile)
  })

  useEffect(() => {
    if (profile.userId !== undefined) {
      HRConnect.post('/initUsers/info', { q: profile.userId })
        .then((res) => {
          console.log(res.data)
          setUserData(res.data)
        })
        .catch((e) => console.log(e.message))
    }
  }, [profile])

  useEffect(() => {
    const updateCounter = async () => {
      await HRConnect.post('/counter', {
        page: 'driver',
        line_id: profile.userId,
      })
        .then((res) => {
          setIsLoading(false)
          console.log(res.data)
        })
        .catch((e) => console.log(e.message))
    }

    if (userData[0] !== undefined) {
      updateCounter()
    }
  }, [userData])

  const getDNList = (startDate, endDate) => {
    setIsLoading(true)

    SVLDriver.get(
      `/driverDNperDay?begin_date=${startDate}&end_date=${endDate}`,
      {
        auth: {
          username: 'hrconnect',
          password: `unicorn_2018:${userData[0].thai_id}`,
        },
      }
    )
      .then((res) => {
        setDailyData(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error.message)
        setIsLoading(false)
      })
  }

  const handleThisMonth = () => {
    setButtonMode(2)
    const startOfMonth = moment().startOf('month').format('DD/MM/YYYY')
    const endOfMonth = moment().endOf('month').format('DD/MM/YYYY')

    //console.log(startOfMonth);

    getDNList(startOfMonth, endOfMonth)
  }

  const handleLastMonth = () => {
    setButtonMode(1)
    const startOfMonth = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('DD/MM/YYYY')
    const endOfMonth = moment()
      .subtract(1, 'month')
      .endOf('month')
      .format('DD/MM/YYYY')

    //console.log(startOfMonth);

    getDNList(startOfMonth, endOfMonth)
  }

  return (
    <div className={isLoading ? 'ui loading segment' : 'ui segment'}>
      <div className="ui fluid buttons" style={{ marginBottom: '5px' }}>
        <button
          className={
            buttonMode === 1 ? 'ui primary  button' : 'ui primary basic button'
          }
          onClick={() => handleLastMonth()}
        >
          DN เดือนก่อน
        </button>
        <button
          className={
            buttonMode === 2 ? 'ui primary  button' : 'ui primary basic button'
          }
          onClick={() => handleThisMonth()}
        >
          DN เดือนนี้
        </button>
      </div>
      <DNList data={dialyData} loading={isLoading} pic={profile.pictureUrl} />
    </div>
  )
}

export default Daily
