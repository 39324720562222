import React, { useState, useEffect } from 'react'
import './index.css'
import WeeklyList from './WeeklyList'
import HRConnect from '../../apis/hrconnect'
import SVLDriver from '../../apis/svldriver'

const liff = window.liff

const Weekly = () => {
  const [profile, setProfile] = useState({})
  const [userData, setUserData] = useState([])
  const [weeklyData, setWeeklyData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (Object.keys(profile).length === 0) {
      liff.init(
        { liffId: '1653813164-YD2g2jeo' },
        async () => {
          if (liff.isLoggedIn()) {
            setProfile(await liff.getProfile())
          } else {
            //liff.login({ redirectUri: 'http://localhost:3000/weekly' })
            liff.login()
          }
        },
        (e) => console.log(e.message)
      )
    }
    console.log(profile)
  }, [])

  useEffect(() => {
    if (profile.userId !== undefined) {
      HRConnect.post('/initUsers/info', { q: profile.userId })
        .then((res) => {
          console.log(res.data)
          setUserData(res.data)
        })
        .catch((e) => console.log(e.message))
    }
  }, [profile])

  useEffect(() => {
    const updateCounter = async () => {
      await HRConnect.post('/counter', {
        page: 'driver-weekly',
        line_id: profile.userId,
      })
        .then((res) => {
          setIsLoading(false)
          console.log(res.data)
        })
        .catch((e) => console.log(e.message))
    }

    const getWeeklyData = async () => {
      await SVLDriver.get(`/empLHWeekly?id_card=${userData[0].thai_id}`, {
        auth: {
          username: 'hrconnect',
          password: 'unicorn_2018',
        },
      })
        .then((res) => {
          console.log(res.data)
          setWeeklyData(res.data)
          setIsLoading(false)
        })
        .catch((err) => console.log(err.message))
    }

    if (userData[0] !== undefined) {
      updateCounter()
      getWeeklyData()
    }
  }, [userData])

  if (isLoading) {
    return (
      <div className="ui segment">
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
        <p></p>
      </div>
    )
  }

  return (
    <div className="myContainer">
      <div className="ui raised  text container segment">
        <h2 className="ui header">
          <img className="ui medium circular image" src={profile.pictureUrl} />
          สวัสดี , {weeklyData.emp_name}
        </h2>
      </div>
      <WeeklyList data={weeklyData} />
    </div>
  )
}

export default Weekly
