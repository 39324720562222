import React from 'react'

const numFormat = (value) =>
  new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(value)

const DNItem = ({ data }) => {
  return (
    <tr>
      <td>{data.truck_no}</td>
      <td>{data.dn_no}</td>
      <td>{data.dn_date}</td>
      <td style={{ textAlign: 'right' }}>{numFormat(data.fuel_cash)}</td>
      <td style={{ textAlign: 'right' }}>{numFormat(data.clear_cash)}</td>
      <td style={{ textAlign: 'right' }}>{numFormat(data.advance_cash)}</td>
      <td style={{ textAlign: 'right' }}>{numFormat(data.express)}</td>
      <td style={{ textAlign: 'right' }}>{numFormat(data.net)}</td>
    </tr>
  )
}

const WeeklyItem = ({ data }) => {
  const DNItems = data.dn.map((item) => <DNItem key={item.dn_no} data={item} />)

  return (
    <div className="ui raised  text container segment">
      <div className="ui top attached blue label">
        <h4 className="ui header" style={{ color: 'white' }}>
          เงินประจำสัปดาห์งวด {data.processdate}
        </h4>
      </div>

      <div className="ui equal width grid">
        <div className="column">
          <div className="ui center aligned segment">
            <div className="ui top attached pink label">ยอดยกมา</div>
            <p>{numFormat(data.prv_balance)}</p>
          </div>
        </div>
        <div className="column">
          <div className="ui center aligned segment">
            <div className="ui top attached blue label">ยอดรวม DN</div>
            <p>{numFormat(data.sum_for_dn)}</p>
          </div>
        </div>
        <div className="column">
          <div className="ui center aligned segment">
            <div className="ui top attached green label">ยอดโอน</div>
            <p>{numFormat(data.weekly_amount)}</p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          overflowX: 'scroll',
          paddingBottom: '5px',
          marginTop: '10px',
        }}
      >
        <table className="ui unstackable very compact  blue small table">
          <thead>
            <tr style={{ textAlign: 'center' }}>
              <th>รหัสรถ</th>
              <th>DN</th>
              <th>วันที่</th>
              <th>เงินสดเพิ่ม</th>
              <th>บิลเงินสด</th>
              <th>ค่าเที่ยว</th>
              <th>ทางด่วน</th>
              <th>รวม</th>
            </tr>
          </thead>
          <tbody>{DNItems}</tbody>
          <tfoot>
            <tr>
              <th></th>
              <th className="center aligned">ยอดรวม</th>
              <th></th>
              <th className="right aligned">{numFormat(data.sum_fuel_cash)}</th>
              <th className="right aligned">{numFormat(data.sum_bill_fuel)}</th>
              <th className="right aligned">{numFormat(data.sum_adv_cash)}</th>
              <th className="right aligned">
                {numFormat(data.sum_oth_amount)}
              </th>
              <th className="right aligned">{numFormat(data.sum_net)}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

const WeeklyList = ({ data }) => {
  if (Object.keys(data).length === 0) {
    return (
      <div className="ui raised  text container segment">
        <div className="ui active inverted dimmer ">
          <div className="ui text loader">Loading</div>
        </div>
        <p></p>
      </div>
    )
  }

  const WeeklyItems = data.weekly.map((item) => (
    <WeeklyItem key={item.processdate} data={item} />
  ))

  return (
    <>
      <div className="ui raised  text container segment">
        <h4 className="ui header">รหัสพนักงาน : {data.emp_no}</h4>
      </div>
      {WeeklyItems}
    </>
  )
}

export default WeeklyList
