import React, { useState, useEffect } from 'react'
import moment from 'moment'
import HRConnect from '../../apis/hrconnect'
import SVLDriver from '../../apis/svldriver'

const liff = window.liff

const Vaccine = () => {
  const [profile, setProfile] = useState({})
  const [userData, setUserData] = useState([])
  const [vaccine, setVaccine] = useState({})

  useEffect(() => {
    if (Object.keys(profile).length === 0) {
      liff.init(
        { liffId: '1653813164-Kap4pEwJ' },
        async () => {
          if (liff.isLoggedIn()) {
            setProfile(await liff.getProfile())
          } else {
            //liff.login({ redirectUri: 'http://localhost:3000/daily' })
            liff.login()
          }
        },
        (e) => console.log(e.message)
      )
    }
    console.log(profile)
  })

  useEffect(() => {
    if (profile.userId !== undefined) {
      HRConnect.post('/initUsers/info', { q: profile.userId })
        .then((res) => {
          console.log(res.data)
          setUserData(res.data)
        })
        .catch((e) => console.log(e.message))
    }
  }, [profile])

  useEffect(() => {
    if (userData.length > 0) {
      getVaccine(userData[0].thai_id)
    }
  }, [userData])

  const getVaccine = (thai_id) => {
    SVLDriver.get(`/vaccine?id_card=${userData[0].thai_id}`, {
      auth: {
        username: 'hrconnect',
        password: `unicorn_2018`,
      },
    }).then((res) => {
      console.log(res.data)
      if (res.data['cov-19'].length > 0) {
        setVaccine(res.data['cov-19'][0])
      }
    })
  }

  if (userData.length === 0) {
    return <div></div>
  }

  if ('emp_no' in vaccine) {
    return (
      <div className="ui raised container segment">
        <div className="ui grid">
          <div className="sixteen wide column">
            <h3 className="ui icon green header">
              <i className="stethoscope icon"></i>
              <div className="content">
                ระบบฐานข้อมูลพนักงานขับรถผู้ฉีดวัคซีน
                <div className="sub header">
                  กรุณาตรวจสอบหากข้อมูลไม่ครบถ้วนกรุณาแจ้งฝ่ายบุคคล
                </div>
              </div>
            </h3>
          </div>
          <div className="sixteen wide column">
            <h4 className="ui center aligned header">ข้อมูลผู้ใช้งาน</h4>
          </div>
          <div className="sixteen wide column">
            <div className="ui segment">
              <div className="sixteen wide column">บัญชีของคุณ</div>
              <div className="sixteen wide column">
                <div className="ui divider"></div>
              </div>
              <div className="sixteen wide column">
                <h3 className="ui green center aligned header">
                  {vaccine.name}
                  <br />
                  {vaccine.company}
                </h3>
              </div>

              <div className="sixteen wide column">
                <div className="ui divider"></div>
              </div>
              <div className="sixteen wide column">
                <h5 className="ui green  header">
                  ข้อมูลการฉีดวัดซีน เข็มที่ 1
                </h5>
                <div>วันที่ฉีด : {vaccine.first}</div>
                <div>ชื่อวัคซีน : {vaccine.first_vaccine}</div>
              </div>
              <div className="ui divider"></div>
              <div className="sixteen wide column">
                <h5 className="ui green  header">
                  ข้อมูลการฉีดวัดซีน เข็มที่ 2
                </h5>
                <div>วันที่ฉีด : {vaccine.second}</div>
                <div>ชื่อวัคซีน : {vaccine.second_vaccine}</div>
              </div>
              <div className="ui divider"></div>
              <div className="sixteen wide column">
                <h5 className="ui green  header">
                  ข้อมูลการฉีดวัดซีน เข็มที่ 3
                </h5>
                <div>วันที่ฉีด : {vaccine.third}</div>
                <div>ชื่อวัคซีน : {vaccine.third_vaccine}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="ui raised container segment">
      <div className="ui grid">
        <div className="sixteen wide column">
          <h3 className="ui icon green header">
            <i className="stethoscope icon"></i>
            <div className="content">
              ระบบฐานข้อมูลพนักงานขับรถผู้ฉีดวัคซีน
              <div className="sub header">
                กรุณาตรวจสอบหากข้อมูลไม่ครบถ้วนกรุณาแจ้งฝ่ายบุคคล
              </div>
            </div>
          </h3>
        </div>
        <div className="sixteen wide column">
          <h4 className="ui center aligned header">ข้อมูลผู้ใช้งาน</h4>
        </div>
        <div className="sixteen wide column">
          <div className="ui segment">
            <div className="sixteen wide column">บัญชีของคุณ</div>
            <div className="sixteen wide column">
              <div className="ui divider"></div>
            </div>
            <div className="sixteen wide column">
              <h3 className="ui green center aligned header">
                {userData[0].full_name}
              </h3>
              <h5 className="ui green center aligned header">
                {userData[0].title}
              </h5>
            </div>

            <div className="ui divider"></div>

            <div className="sixteen wide column">
              <h5 className="ui red center aligned  header">
                ระบบสำหรับนี้สำหรับ พนักงานขับรถ/สรั่งเรือ เท่านั้น
              </h5>
            </div>
            <div className="ui divider"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Vaccine
