import React, { useState } from 'react'
//import PDFViewer from 'pdf-viewer-reactjs'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const SlipViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  if (url === '') {
    return ''
  }

  //   let pdf = { url: url }

  //   return <PDFViewer document={pdf} navbarOnTop rotationAngle={90} />
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
    <>
      <div className="ui segment">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            <i className="angle left icon"></i>
          </button>
          <div style={{ marginLeft: '5px', marginRight: '5px' }}>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </div>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            <i className="angle right icon"></i>
          </button>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          overflowX: 'scroll',
          overflowY: 'scroll',
          paddingBottom: '5px',
          marginTop: '10px',
        }}
      >
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
    </>
  )
}

export default SlipViewer
