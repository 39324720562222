import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PickpointAPI from '../../apis/pickpoint'

const getPeriodShow = () => {
  let periods = []

  for (let i = 0; i < 3; i = i + 1) {
    let period = moment().subtract(i, 'month').format('YYYYMM')
    let text = moment().subtract(i, 'month').format('MMM')

    periods.push({ key: i, period: period, text: text })
  }

  return periods.reverse()
}

const HistoryList = ({ data, pic }) => {
  console.log('data.....', data)
  if (data !== null) {
    return (
      <>
        <h2 className="ui header">
          <i className="flag checkered icon"></i>
          <div className="content">ประวัติการส่งข้อมูล</div>
        </h2>
        <a className="ui big blue image label">
          <img src={pic} />
          {data.Persons[0].emp}
          <div className="detail">{data.Persons[0].total_point} คะแนน</div>
        </a>
        <table className="ui  small  unstackable blue table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Event</th>
              <th>Value</th>
              <th>Point</th>
            </tr>
          </thead>
          <tbody>
            {data.Persons[0].svl_events !== null &&
              data.Persons[0].svl_events.map((i) => (
                <tr key={`${i.event_date}-${i.code}`}>
                  <td>{i.event_date}</td>
                  <td>{i.description}</td>
                  <td className="center aligned">{i.event_unit}</td>
                  <td className="center aligned">{i.point}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </>
    )
  }

  return <div>Loading...</div>
}

const History = ({ userData, onBack }) => {
  const [historyData, setHistoryData] = useState(null)

  useEffect(() => {
    let period = moment().format('YYYYMM')
    getPeriodData(period, userData[0].thai_id)
  }, [])

  const getPeriodData = (period, idcard) => {
    let begindate = moment(`${period}01`, 'YYYYMMDD').format('DD/MM/YYYY')

    let enddate = moment(`${period}01`, 'YYYYMMDD')
      .endOf('month')
      .format('DD/MM/YYYY')

    PickpointAPI.get(
      `/queryPointByID?id_card=${idcard}&begin_date=${begindate}&end_date=${enddate}`
    )
      .then((res) => {
        console.log('getPeriodData', res.data)
        setHistoryData(res.data)
      })
      .catch((error) => console.log(error.response))
  }

  const renderPeriod = getPeriodShow().map((item) => (
    <button
      className="ui button"
      onClick={() => getPeriodData(item.period, userData[0].thai_id)}
      key={item.period}
    >
      {item.text}
    </button>
  ))

  return (
    <div style={{ padding: '10px' }}>
      <div className="four blue ui buttons">{renderPeriod}</div>
      <div className="ui hidden divider"></div>
      <div className="ui right floated green button" onClick={() => onBack()}>
        กลับ
      </div>
      <HistoryList data={historyData} pic={''} />
      <div className="ui green button" onClick={() => onBack()}>
        กลับหน้าหลัก
      </div>
    </div>
  )
}

export default History
