import React, { useState, useEffect } from 'react'

const liff = window.liff

const Register = () => {
  const [profile, setProfile] = useState({})
  const [thaiid, setThaiid] = useState('')
  const [secret, setSecret] = useState('')

  useEffect(() => {
    if (Object.keys(profile).length === 0) {
      liff.init(
        { liffId: '1653813164-zlOJOq61' },
        async () => {
          if (liff.isLoggedIn()) {
            setProfile(await liff.getProfile())
          } else {
            //liff.login({ redirectUri: 'http://localhost:3000/register' })
            liff.login()
          }
        },
        (e) => console.log(e.message)
      )
    }
    console.log(profile)
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(thaiid, secret)
    liff
      .sendMessages([
        {
          type: 'text',
          text: 'ลงทะเบียน ' + thaiid + ' ' + secret,
        },
      ])
      .then(() => {
        console.log('message sent')
        liff.closeWindow()
      })
      .catch((err) => {
        console.log('error', err)
        liff.closeWindow()
      })
  }

  return (
    <div class="ui raised segment">
      <div>สวัสดี {profile.displayName}</div>
      <form class="ui form" onSubmit={handleSubmit}>
        <div class="field">
          <label>เลขบัตรประชาชน</label>
          <input
            type="text"
            name="thai_id"
            placeholder="Thai National ID"
            onChange={(e) => setThaiid(e.target.value)}
          />
        </div>
        <div class="field">
          <label>รหัสยืนยันตัวตน</label>
          <input
            type="text"
            name="secret_code"
            placeholder="Secret Code"
            onChange={(e) => setSecret(e.target.value)}
          />
        </div>
        <button class="ui button" type="submit">
          สมัครสมาชิก
        </button>
      </form>
    </div>
  )
}

export default Register
