import React, { useState, useEffect } from 'react'
import moment from 'moment'
import PickpointAPI from '../../apis/pickpoint'

const getPeriodShow = () => {
  let periods = []

  for (let i = 0; i < 3; i = i + 1) {
    let period = moment().subtract(i, 'month').format('YYYYMM')
    let text = moment().subtract(i, 'month').format('MMM')

    periods.push({ key: i, period: period, text: text })
  }

  return periods.reverse()
}

const Ranking = ({ userData, onBack }) => {
  const [rankingData, setRankingData] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [rankingDetail, setRankingDetail] = useState(null)
  const [viewDetail, setViewDetail] = useState(false)

  useEffect(() => {
    let period = moment().format('YYYYMM')
    getPeriodData(period)
  }, [])

  const RankingList = ({ data, userData }) => {
    return (
      <table className="ui blue small unstackable striped  table">
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Day</th>
            <th>Event</th>
            <th>Point</th>
          </tr>
        </thead>
        <tbody>
          {data !== null &&
            data.map((i) => (
              <tr
                key={i.id_card}
                onClick={() => getDetail(i.id_card)}
                className={userData[0].thai_id === i.id_card ? 'warning' : ''}
              >
                <td>{i.rank_no}</td>
                <td>{i.emp}</td>
                <td>{i.number_of_day}</td>
                <td>{i.event}</td>
                <td>{i.total_point}</td>
              </tr>
            ))}
        </tbody>
      </table>
    )
  }

  const DetailList = ({ data }) => {
    if (data.Persons !== undefined) {
      return (
        <div style={{ padding: '10px' }}>
          <div
            className="ui right floated green button"
            onClick={() => setViewDetail(false)}
          >
            กลับ
          </div>
          <h2 className="ui header">Detail</h2>

          <div className="ui segment">
            สถิติข้อมูลของ {data.Persons[0].emp} คะแนน{' '}
            {data.Persons[0].total_point}
          </div>
          <table className="ui blue small unstackable striped  table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Event</th>
                <th>Value</th>
                <th>Point</th>
              </tr>
            </thead>
            <tbody>
              {data.Persons[0].svl_events !== null &&
                data.Persons[0].svl_events.map((i) => (
                  <tr key={i.key}>
                    <td>{i.event_date}</td>
                    <td>{i.description}</td>
                    <td>{i.event_unit}</td>
                    <td>{i.point}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="ui green button" onClick={() => setViewDetail(false)}>
            กลับ
          </div>
        </div>
      )
    }

    return <div>Loading...</div>
  }

  const renderPeriod = getPeriodShow().map((item) => (
    <button
      className="ui button"
      onClick={() => getPeriodData(item.period)}
      key={item.period}
    >
      {item.text}
    </button>
  ))

  const getPeriodData = (period) => {
    setSelectedPeriod(period)
    let begindate = moment(`${period}01`, 'YYYYMMDD').format('DD/MM/YYYY')

    let enddate = moment(`${period}01`, 'YYYYMMDD')
      .endOf('month')
      .format('DD/MM/YYYY')

    PickpointAPI.get(
      `/queryEmpSummary?begin_date=${begindate}&end_date=${enddate}`
    )
      .then((res) => {
        console.log('getPeriodData', res.data)
        setRankingData(res.data)
      })
      .catch((error) => console.log(error.response))
  }

  const getDetail = (id) => {
    let begindate = moment(`${selectedPeriod}01`, 'YYYYMMDD').format(
      'DD/MM/YYYY'
    )
    let enddate = moment(`${selectedPeriod}01`, 'YYYYMMDD')
      .endOf('month')
      .format('DD/MM/YYYY')

    PickpointAPI.get(
      `/queryPointByID?id_card=${id}&begin_date=${begindate}&end_date=${enddate}`
    )
      .then((res) => {
        console.log('getPeriodData', res.data)
        setRankingDetail(res.data)
        setViewDetail(true)
      })
      .catch((error) => console.log(error.response))
  }

  if (viewDetail) {
    return <DetailList data={rankingDetail} />
  }

  return (
    <div style={{ padding: '10px' }}>
      <div className="four blue ui buttons">{renderPeriod}</div>
      <div className="ui hidden divider"></div>
      <div className="ui right floated green button" onClick={() => onBack()}>
        กลับ
      </div>
      <h1 className="ui header">
        Ranking
        <div className="sub header">คลิ๊กที่ชื่อเพื่อดูรายละเอียด</div>
      </h1>
      <RankingList data={rankingData} pic={''} userData={userData} />
      <div className="ui green button" onClick={() => onBack()}>
        กลับหน้าหลัก
      </div>
    </div>
  )
}

export default Ranking
