import React from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import HRConnect from '../../apis/hrconnect'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    // Don't call this.setState() here!
    this.state = { result: [], q: [], error: '' }

    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
  }

  renderResult() {
    if (this.state.error) {
      return <h1>{this.state.error}</h1>
    }

    if (this.state.result.length > 0) {
      return (
        <React.Fragment>
          {this.state.result.map((contact) => (
            <div className="ui segment" key={contact.id}>
              {contact.emp_status !== 'A' && (
                <div className="ui red  top attached label">สถานะ: ลาออก</div>
              )}
              <div className="ui grid">
                <div className="four wide column">
                  {contact.line_profile !== null
                    ? this.renderAvatar(contact.line_profile.line_picture_url)
                    : this.renderAvatar(undefined)}
                </div>
                <div className="twelve wide column">
                  <h3 className="ui blue header">
                    {contact.full_name}{' '}
                    {contact.nick_name ? `(${contact.nick_name})` : ''}
                  </h3>
                  <div>
                    <b>
                      {contact.eng_name} {contact.eng_surname}
                      {contact.emp_code ? `- ${contact.emp_code}` : ''}
                    </b>
                  </div>
                  <div className="ui tiny teal label">{contact.title}</div>
                </div>
                <div className="eight wide column">
                  {this.renderEmail(contact.email)}
                  <div style={{ marginTop: '3px' }}>
                    {this.renderLine(contact.line)}
                  </div>
                </div>
                <div className="right aligned eight wide column">
                  <div>{this.renderExt(contact.internal_extension)}</div>
                  <div style={{ marginTop: '3px' }}>
                    {this.renderPhone(contact.personal_phone_no, 'blue')}
                    {this.renderPhone(contact.company_phone_no, 'red')}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </React.Fragment>
      )
    }

    return <div>Please Searching {this.state.q}</div>
  }

  renderPhone(number, color) {
    const className = `ui ${color} tiny label`
    if (number) {
      const phoneSyntax = 'tel:' + number
      return (
        <a className={className} href={phoneSyntax}>
          {number}
        </a>
      )
    }

    return ''
  }

  renderExt(number) {
    if (number) {
      return <div className="ui tiny label">Ext: {number}</div>
    }

    return ''
  }

  renderEmail(email) {
    if (email) {
      const emailSyntax = 'mailto:' + email
      return (
        <a className="ui tiny label" href={emailSyntax}>
          <i className="mail icon" /> {email}
        </a>
      )
    }

    return ''
  }

  renderLine(line) {
    if (line) {
      return (
        <div className="ui small green label">
          <i className="linechat icon" /> {line}
        </div>
      )
    }

    return ''
  }

  renderAvatar(img) {
    if (img !== undefined) {
      return (
        <img
          className="ui tiny circular image"
          src={img}
          ref={(img) => (this.img = img)}
          onError={() =>
            (this.img.src =
              'https://ced-iadr2021.com/wp-content/uploads/2021/06/no-user-image.gif')
          }
        />
      )
    }

    return <i className="huge user circle icon"></i>
  }

  addDefaultSrc(ev) {
    ev.target.src =
      'https://ced-iadr2021.com/wp-content/uploads/2021/06/no-user-image.gif'
  }

  renderNoData() {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'ไม่พบข้อมูลที่ค้นหา',
    })
  }

  handleFieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  async handleSearch(event) {
    event.preventDefault()
    console.log('q', this.state.q)

    if (this.state.q.length > 0) {
      const response = await HRConnect.post('/inituser/search', {
        q: this.state.q,
      })

      this.setState({ result: response.data, error: '' })
    } else {
      this.setState({
        result: [],
        error: 'กรุณากรอกข้อมูลที่ต้องการค้นหา',
      })
    }

    if (this.state.result.length === 0) {
      this.setState({ error: 'ไม่พบข้อมูลที่ค้นหา' })
      this.renderNoData()
    }

    console.log(this.state.result)
  }

  render() {
    return (
      <div>
        <div className="ui blue segment">
          <h2 className="ui header">
            <i className="search icon" />
            <div className="content">ค้นหาเลขหมายโทรศัพท์</div>
          </h2>
          <form onSubmit={this.handleSearch}>
            <div className="ui fluid action input">
              <input
                type="text"
                placeholder="ค้นหาโดย ชื่อ ชื่อเล่น หรือ หน่วยงาน"
                name="q"
                value={this.state.q}
                onChange={this.handleFieldChange}
              />
              <button className="ui button" type="submit">
                ค้นหา
              </button>
            </div>
          </form>
        </div>
        {this.renderResult()}
      </div>
    )
  }
}

export default Contact
