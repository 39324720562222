import React, { useState } from 'react'
import moment from 'moment'
import SlipViewer from './SlipViewer'

const MonthlyList = ({ userData, data, profile }) => {
  const [viewPdf, setViewPdf] = useState(false)
  const [seletedUrl, setSeletedUrl] = useState('')

  if (viewPdf) {
    return (
      <>
        <div className="ui segment">
          <div className="two ui buttons" style={{ marginBottom: '5px' }}>
            <button
              className=" ui blue button"
              onClick={() => setViewPdf(false)}
              style={{ margin: '0px' }}
            >
              กลับหน้าหลัก
            </button>
            <div className="or"></div>
            <a href={seletedUrl} className=" ui blue button">
              ดาวโหลดไฟล์
            </a>
          </div>
        </div>
        <SlipViewer url={seletedUrl} />
      </>
    )
  }

  return (
    <div className="ui segment">
      <h4 className="ui top attached header" style={{ textAlign: 'center' }}>
        <i className="money bill alternate outline icon"></i>
        ใบสรุปสิทธิประโยชน์รายเดือน
      </h4>
      <div className="ui segment">
        {userData.length > 0 && (
          <>
            <img
              className="ui tiny circular left floated image"
              src={profile.pictureUrl}
            ></img>
            <h4>{userData[0].full_name}</h4>
            <p style={{ marginBottom: '50px' }}>{userData[0].title}</p>
          </>
        )}

        <h4 className="ui horizontal divider header">เลือกงวดจ่าย</h4>
        <div className="ui grid">
          <div className="ui sixteen wide column">
            {data.map((i) => (
              <div className="row" style={{ padding: '1px' }}>
                <button
                  className=" fluid ui blue button"
                  style={{ justifyContent: 'center' }}
                  key={i.paydate}
                  onClick={() => {
                    setSeletedUrl(i.url)
                    setViewPdf(true)
                  }}
                >
                  {moment(i.paydate, 'DDMMYYYY', 'th').format('DD MMMM YYYY')}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonthlyList

{
  /* <div className="fluid ui vertical buttons">
{data.map((i) => (
  <button
    className=" ui blue button"
    style={{ justifyContent: 'center' }}
    key={i.paydate}
    onClick={() => {
      setSeletedUrl(i.url)
      setViewPdf(true)
    }}
  >
    {moment(i.paydate, 'DDMMYYYY', 'th').format('DD MMMM YYYY')}
  </button>
))}
</div> */
}
