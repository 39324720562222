import React, { useState, useEffect } from 'react'
import moment from 'moment'
import './index.css'
import HRConnect from '../../apis/hrconnect'
import PickpointAPI from '../../apis/pickpoint'
import History from './History'
import Ranking from './Ranking'

const liff = window.liff
const activity = [
  {
    title: 'Jogging',
    unit: 'K.M.',
    detail: 'วิ่งจ๊อกกิ้ง',
    condition: '1 ก.ม. = 1 คะแนน',
    color: 'red',
    code: 'jo',
  },
  {
    title: 'Bike',
    unit: 'K.M.',
    detail:
      'ปั่นจักรยาน (ทุกประเภท เช่น จักรยานใน Fitness หรือจักรยานกลางแจ้ง (Road Bike))',
    condition: '2 ก.ม. = 1 คะแนน',
    color: 'orange',
    code: 'bi',
  },
  {
    title: 'Swiming',
    unit: 'Min',
    detail: 'ว่ายน้ำ',
    condition: '5 นาที = 1 คะแนน',
    color: 'yellow',
    code: 'sw',
  },
  {
    title: 'Sport',
    unit: 'Min',
    detail: 'กีฬา เช่น ฟุตบอล บาสเก็ตบอล แบตมินตัน',
    condition: '10 นาที = 1 คะแนน',
    color: 'green',
    code: 'sp',
  },
  {
    title: 'Weight',
    unit: 'Min',
    detail: 'ใช้อุปกรณ์ Weight Training  ในการออกกำลังกาย',
    condition: '10 นาที = 1 คะแนน',
    color: 'teal',
    code: 'wt',
  },
  {
    title: 'Class',
    unit: 'Min',
    detail: 'เข้าร่วมกิจกรรม Class Room เช่น โยคะ , Zumba Dance และอื่นๆ',
    condition: '10 นาที = 1 คะแนน',
    color: 'violet',
    code: 'cl',
  },
  {
    title: 'Dhamma',
    unit: 'Min',
    detail: 'เข้าร่วมกิจกรรมชมรมธรรมะ',
    condition: '10 นาที = 1 คะแนน',
    color: 'purple',
    code: 'dh',
  },
  {
    title: 'Squat',
    unit: 'ครั้ง',
    detail: 'ทำกิจกรรม Squat',
    condition: '10 ครั้ง = 1 คะแนน',
    color: 'pink',
    code: 'sq',
  },
  {
    title: 'Knee PushUp',
    unit: 'ครั้ง',
    detail: 'ทำกิจกรรม Knee PushUp',
    condition: '5 ครั้ง = 1 คะแนน',
    color: 'blue',
    code: 'ku',
  },
  {
    title: 'Walk (Step)',
    unit: 'Step',
    detail:
      'เดิน (ทุกประเภท เช่น เดินบนลู่ เดินบนถนน หรือเดินออกกำลังกาย เป็นต้น',
    condition: '1000 ก้าว = 1 คะแนน',
    color: 'blue',
    code: 'st',
  },
  {
    title: 'N/B Stretching',
    unit: 'ครั้ง',
    detail: 'Neck and Back Stretching',
    condition: '10 ครั้ง = 1 คะแนน',
    color: 'violet',
    code: 'jj',
  },
]

const getDateOption = () => {
  let option = []

  for (let i = 0; i < 7; i = i + 1) {
    let mydate = moment().subtract(i, 'day').format('DD/MM/YYYY')
    option.push({ key: i, value: mydate, text: mydate })
  }

  return option
}

const Pickpoint = () => {
  const [profile, setProfile] = useState({})
  const [userData, setUserData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectActivity, setSelectActivity] = useState(-1)
  const [submitDate, setSubmitDate] = useState('')
  const [q, setQ] = useState([])
  const [resultCode, setResultCode] = useState(0)
  const [view, setView] = useState('main')

  useEffect(() => {
    if (Object.keys(profile).length === 0) {
      liff.init(
        { liffId: '1653813164-rm5b5DpP' },
        async () => {
          if (liff.isLoggedIn()) {
            setProfile(await liff.getProfile())
          } else {
            //liff.login({ redirectUri: 'http://localhost:3000/ppp' })
            liff.login()
          }
        },
        (e) => console.log(e.message)
      )
    }
    console.log(profile)
  }, [])

  useEffect(() => {
    if (profile.userId !== undefined) {
      HRConnect.post('/initUsers/info', { q: profile.userId })
        .then((res) => {
          console.log(res.data)
          setUserData(res.data)
        })
        .catch((e) => console.log(e.message))
    }
  }, [profile])

  useEffect(() => {
    const updateCounter = async () => {
      await HRConnect.post('/counter', {
        page: 'ppp',
        line_id: profile.userId,
      })
        .then((res) => {
          setIsLoading(false)
          console.log(res.data)
        })
        .catch((e) => console.log(e.message))
    }

    if (userData[0] !== undefined) {
      updateCounter()
    }
  }, [userData])

  const MyDropDown = () => {
    return (
      <select
        className="ui dropdown"
        value={submitDate}
        onChange={(e) => setSubmitDate(e.target.value)}
      >
        <option value="">Select Date</option>
        {getDateOption().map((i) => (
          <option value={i.value} key={i.key}>
            {i.text}
          </option>
        ))}
      </select>
    )
  }

  const renderActivity = activity.map((item, index) => (
    <div className="column" key={item.title}>
      <div
        className={`fluid ui ${item.color} button`}
        id={index}
        onClick={(e) => setSelectActivity(e.target.id)}
      >
        {item.title}
      </div>
    </div>
  ))

  const ResultScreen = () => {
    if (resultCode === 201) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div className="ui center aligned one column centered grid">
            <div className="center aligned column">
              <i className="massive green check circle icon"></i>

              <h1 className="header">ส่งข้อมูลสำเร็จ</h1>
              <div className="ui blue button" onClick={clear}>
                ส่งข้อมูลกิจกรรมอื่น
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      // มี error
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div className="ui center aligned one column centered grid">
            <div className="center aligned column">
              <i className="massive red times circle icon"></i>

              <h1 className="header">เกิดข้อผิดพลาด</h1>
              <div className="ui red button" onClick={clear}>
                ลองใหม่อีกครั้ง
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const submit = async (e) => {
    e.preventDefault()

    await PickpointAPI.post('/pick_point', {
      id_card: userData[0].thai_id,
      date: submitDate,
      code: activity[selectActivity].code,
      value: q,
      personal_data: {
        emp_name: userData[0].name,
        emp_surname: userData[0].surname,
        dept: userData[0].title,
        company: 'SVL',
      },
    })
      .then((res) => {
        console.log(res)
        setResultCode(res.status)
      })
      .catch((error) => {
        console.log(error.response)
        setResultCode(error.response.status)
      })
  }

  const clear = () => {
    setQ('')
    setSubmitDate('')
    setSelectActivity(-1)
    setResultCode(0)
    setView('main')
  }

  if (isLoading) {
    return (
      <div className="ui segment">
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Loading</div>
        </div>
        <p></p>
      </div>
    )
  }

  if (view === 'history') {
    return <History userData={userData} onBack={clear} />
  }

  if (view === 'ranking') {
    return <Ranking userData={userData} onBack={clear} />
  }

  if (resultCode !== 0) {
    return <ResultScreen />
  }

  return (
    <div className="myContainer">
      <div className="ui equal width padded grid">
        <div className="row">
          <div className="column">
            <div className="ui medium header">
              <i className="futbol outline icon"></i>
              กรุณาเลือกกิจกรรมที่รายงาน
            </div>
          </div>
        </div>
        <div className="three column row activity">{renderActivity}</div>
        {selectActivity < 0 && (
          <>
            <div className="row">
              <div className="column">
                <div className="ui horizontal divider">Or</div>
                <div
                  className={
                    userData !== ''
                      ? 'fluid ui pink button'
                      : 'fluid ui pink disabled button'
                  }
                  onClick={() => {
                    setView('history')
                  }}
                >
                  ตรวจสอบประวัติ
                </div>
              </div>
            </div>
            <div className="row">
              <div className="column">
                <div
                  className="fluid ui brown button"
                  onClick={() => setView('ranking')}
                >
                  อันดับคะแนนรวม
                </div>
              </div>
            </div>
          </>
        )}
        {selectActivity >= 0 && (
          <div>
            <div>
              <h3 className="ui top attached header">
                คุณเลือกกิจกรรม {activity[selectActivity].title}
              </h3>
              <div className="ui attached segment">
                <div className="ui small header">
                  {activity[selectActivity].detail}
                </div>
                <MyDropDown />
                <div className="ui hidden divider"></div>
                <div className="ui  right labeled input">
                  <input
                    type="number"
                    name="AccValue"
                    id="AccValue"
                    placeholder="ระบุจำนวน"
                    onChange={(e) => setQ(e.target.value)}
                    value={q}
                  />
                  <div className="ui  label">
                    {activity[selectActivity].unit}
                  </div>
                </div>
                <div className="ui hidden divider"></div>
                <div
                  className="ui   blue button"
                  onClick={(e) => {
                    submit(e)
                  }}
                >
                  ส่งข้อมูล
                </div>
                <div
                  className="ui pink button"
                  onClick={() => {
                    setView('history')
                  }}
                >
                  ตรวจสอบประวัติ
                </div>
                <div className="ui red label" style={{ marginTop: '1rem' }}>
                  {activity[selectActivity].condition}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Pickpoint
