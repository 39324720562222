import React from 'react'
import './DNList.css'

const renderAvartar = (q) => {
  if (q === '') {
    return <i className="huge user circle icon" />
  } else {
    return <img className="ui centered tiny circular image" src={q} alt="" />
  }
}

const renderFuelType = (q) => {
  if (q === 'ก.ก.') {
    return 'NGV'
  } else {
    return 'ดีเซล'
  }
}

const renderDest = (q) => {
  return (
    <div className="ui ordered list">
      {q.map((i) => (
        <div className="item" key={i}>
          {i}
        </div>
      ))}
    </div>
  )
}

const DNList = ({ data, loading, pic }) => {
  if (!data) {
    return <div>No Data</div>
  }

  return (
    <div>
      <div className={loading ? 'ui loading segment' : 'ui segment'}>
        <div className="ui header top attached blue label">
          {data.driver} ( {data.id_card} )
        </div>
        <div className="ui grid">
          <div className="row">
            <div className="center aligned five wide column">
              <b>{data.emp_no}</b>
              <div>{renderAvartar(pic)}</div>
              <div className="ui orange circular label">
                <b>{data.DN.length} DN</b>
              </div>
            </div>
            <div className="eleven wide column">
              <div className="ui center aligned two column grid headerbox">
                <div className="column">
                  <div className="ui segment">
                    <div className="ui top attached red label">หนัก/เบา</div>
                    <p>
                      {new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                      }).format(data.Load_noload)}
                      <br />
                      บาท
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div className="ui segment">
                    <div className="ui top attached pink label">ค่ายก</div>
                    <p>
                      {new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                      }).format(data.Lift)}
                      <br />
                      บาท
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div className="ui segment">
                    <div className="ui top attached purple label">งานพ่วง</div>
                    <p>
                      {data.Multidrop}
                      <br />
                      บาท
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div className="ui segment">
                    <div className="ui top attached blue label">ค่ายาก</div>
                    <p>
                      {new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                      }).format(data.Difficulty)}
                      <br />
                      บาท
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div className="ui segments">
                    <div className="ui segment">
                      <div className="ui top attached orange label">
                        ดีเซล(ลิตร)
                      </div>
                      <p>
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                        }).format(data.Diesel)}
                      </p>
                    </div>
                    <div className="ui segment">
                      <div className="ui top attached grey label">ใช้ไป</div>
                      <p>
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                        }).format(data.Diesel_used)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="ui segments">
                    <div className="ui segment">
                      <div className="ui top attached olive label">
                        NGV(ก.ก.)
                      </div>
                      <p>
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                        }).format(data.NGV)}
                      </p>
                    </div>
                    <div className="ui segment">
                      <div className="ui top attached grey label">ใช้ไป</div>
                      <p>
                        {new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                        }).format(data.NGV_used)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {data.DN.map((i) => (
        <div className="ui raised horizontal  segments" key={i.dn_no}>
          <div
            className="ui blue inverted  segment"
            style={{ minWidth: '70px' }}
          >
            <h4>{i.truck_no}</h4>
            <h4>{renderFuelType(i.fuel_unit)}</h4>
          </div>
          <div className="ui segment">
            <div className="ui divided grid">
              <div className="center aligned four wide  column">
                <b>DN</b>
              </div>
              <div className="center alligned twelve wide column">
                <h4>{i.dn_no}</h4>
              </div>
              <div className="center aligned four wide  column">
                <b>Dest.</b>
              </div>
              <div className="center alligned twelve wide column">
                {renderDest(i.destination)}
              </div>
            </div>

            <div className="ui center aligned grid driver-row">
              {i.remark !== 'good' && (
                <div className="sixteen wide column">
                  <div className="ui red inverted segment">
                    รอปรับปรุงระยะทาง
                  </div>
                </div>
              )}

              <div className="eight wide column">
                <div>
                  <b>รวม</b>
                </div>
                <div>
                  {new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                  }).format(
                    i.load_noload + i.multidrop + i.difficulty + i.lift
                  )}
                  <br />
                  บาท
                </div>
              </div>
              <div className="eight wide column">
                <div>
                  <b>หนักเบา</b>
                </div>
                <div>
                  {new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 2,
                  }).format(i.load_noload)}
                  <br />
                  บาท
                </div>
              </div>

              <div className="eight wide column">
                <div>
                  <b>งานพ่วง</b>
                </div>
                <div>{i.multidrop} บาท</div>
              </div>
              <div className="eight wide column">
                <div>
                  <b>ค่ายก</b>
                </div>
                <div>{i.lift} บาท</div>
              </div>
              <div className="eight wide column">
                <div>
                  <b>ค่ายาก</b>
                </div>
                <div>{i.difficulty} บาท</div>
              </div>

              <div className="eight wide column">
                <div>
                  <b>เชื้อเพลิง</b>
                </div>
                <div>
                  {i.fuel_quan} {i.fuel_unit}
                </div>
                <div>
                  ใช้ {i.fuel_used} {i.fuel_unit}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <p>
        หมายเหตุ (ณ วันที่ 28 กันยายน 2565) <br />
        * เงินปรับปรุง เงินตอบแทนพิเศษ คำนวนอยู่ในค่าหนักเบาแล้ว <br />
        * เงินรายได้ อาจมีการหัก ณ สิ้นเดือนตามรายการหัก กรุณาตรวจสอบรายละเอียด
        จาก สลิปรายเดือน <br />
        * ระบบ Fair เริ่มใช้กับงานตั้งแต่วันที่ 1 ตุลาคม 2562 <br />
        * ค่าเชื้อเพลิงใช้ไปอาจแสดงมาก/น้อยกว่าปกติเนื่องจากมีการรวมจากหลาย DN
        ในรอบวิ่งเดียวกัน <br />
        ** DN อาจมีการปรับปรุงรายละเอียดในภายหลังได้ สอบถามเพิ่มเติมกดมาคุยกัน
        **
        <br />
        <a href="http://nav.cx/gGeUgF0">
          <img
            src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png"
            alt="เพิ่มเพื่อน"
            height="36"
            border="0"
          />
        </a>
      </p>
    </div>
  )
}

export default DNList
